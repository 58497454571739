import type { SearchResult } from '@elastic/search-ui'
import { Link, Tag } from 'components/common'
import { processText, handleNavigate } from 'components/search/buckets/utils'
import { useStoreContext } from 'context/StoreProvider'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getRaw, codesystems } from 'utils'

const ChapterResultView = ({ result, searchTerm, addFilter }: SearchResult) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { actions, state } = useStoreContext()

	const { code, scrollRef } = state

	const { updateCodeValue } = actions

	const handleScrollOnClick = () => {
		const scrollToElement = () => {
			const element = scrollRef.current[code as string]
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}

		const delay = 500

		const timeoutId = setTimeout(scrollToElement, delay)

		return () => clearTimeout(timeoutId)
	}

	const handleReplacedByClick = () => {
		handleScrollOnClick()
		updateCodeValue(getRaw(result, 'replaced_by') as string)
		addFilter('active', 'true', 'any')
		navigate(
			`${handleNavigate(getRaw(result, 'code_system') as string)}/chapter/${
				getRaw(result, 'replaced_by') as string
			}`
		)
	}
	return (
		<Row className='d-flex align-items-center gap-1 gap-md-3 w-100' role='row'>
			<Col xs='auto' style={{ width: '80px' }} role='cell' className='p-0'>
				<span>
					{processText('code', searchTerm, false, result)}
					{
						getRaw(result, 'star_code') === 'true' && '*' // Add star if code is a star code
					}
				</span>
			</Col>
			<Col
				role='cell'
				className='text-wrap text-break p-0 d-flex gap-2 flex-column flex-md-row align-items-start align-items-md-center justify-content-between'
			>
				<Row className='d-flex gap-2 p-0'>
					<Col xs='auto' className='p-0'>
						{getRaw(result, 'name_norwegian') &&
							processText('name_norwegian', searchTerm, false, result)}
					</Col>
					{getRaw(result, 'active') === 'false' &&
						result.replaced_by &&
						result.replaced_by.raw &&
						result.code_system.raw && (
							<Col
								className='d-flex gap-1 flex-wrap'
								xs='auto'
								onClick={(event) => {
									event.stopPropagation()
									handleReplacedByClick()
								}}
								onKeyDown={(event) => {
									event.stopPropagation()
									if (event.key === 'Enter' || event.key === ' ') {
										handleReplacedByClick()
									}
								}}
								tabIndex={0}
							>
								{t('general.replacedBy')}:{' '}
								<Link
									text={getRaw(result, 'replaced_by') as string}
									href={`#`}
								/>
							</Col>
						)}
				</Row>
				{result.chapter_code &&
					result.chapter_code.raw &&
					result.code_system.raw === codesystems.phbu && (
						<Col xs='auto' className='p-0'>
							<Tag
								color={'yellow'}
								text={getRaw(result, 'chapter_code') as string}
							/>
						</Col>
					)}
				{getRaw(result, 'is_apat') && getRaw(result, 'is_apat') === 'true' && (
					<Col xs='auto' className='p-0 ms-auto'>
						<Tag color={'yellow'} text={codesystems.apat} />
					</Col>
				)}

				{getRaw(result, 'active') === 'false' && (
					<Col xs='auto' className='p-0 ms-auto'>
						<Tag color={'red'} text={t('general.inactive')} />
					</Col>
				)}
			</Col>
		</Row>
	)
}

export default ChapterResultView
